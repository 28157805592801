<template>
    <div>
        <div class="d-flex align-self-start pa-1" v-if="brandObj.mainContactEmailAddress">
            <v-icon size="18" class="mr-3" :icon="mdiEmail"></v-icon>
            <a class="text-anchor" :href="`mailto:${brandObj.mainContactEmailAddress}`">{{ brandObj.mainContactEmailAddress }}</a>
        </div>
        <div class="d-flex align-self-start pa-1" v-if="brandObj.mainContactPhoneNumber">
            <v-icon size="18" class="mr-3" :icon="mdiPhone"></v-icon>
            <a class="d-block text-anchor" :href="`tel:${brandObj.mainContactPhoneNumber}`">
                {{ brandObj.mainContactPhoneNumberFormatted || brandObj.mainContactPhoneNumber }}
            </a>
        </div>
        <div class="d-flex align-self-start pa-1" v-if="brandObj.company?.officeHoursShort">
            <v-icon size="18" class="mr-3" :icon="mdiClock"></v-icon>
            <span>{{ brandObj.company.officeHoursShort }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { mdiEmail, mdiPhone, mdiClock } from '@mdi/js';

    const ctx = useNuxtApp();
    const brandObj = ctx.$config.public.brand;
</script>

<style lang="scss" scoped>
.v-icon {
    filter: invert(0.5);
}
</style>
