<template>
    <div
        class="footer-main-container w-100 mt-4 px-md-8"
    >
        <v-row
            class="d-flex flex-warp"
            role="navigation"
        >
            <!-- Links -->
            <v-col
                cols="12"
                md="9"
            >
                <template
                    v-if="isMobile"
                >
                    <!-- Secondary NAV -->
                    <MainMenuAccordion
                        v-if="isMobile"
                        :items="navItemsAlt"
                        no-link-title
                    ></MainMenuAccordion>
                </template>
                <template
                    v-else
                >
                    <!-- Primary NAV -->
                    <FooterItems
                        :items="navItems"
                    ></FooterItems>

                    <v-divider
                        class="my-4 my-md-8"
                    ></v-divider>

                    <!-- Secondary NAV -->
                    <FooterItems
                        :items="navItemsAlt"
                        no-link-title
                        no-uppercase-title
                    ></FooterItems>
                </template>
            </v-col>

            <v-divider
                v-if="isMobile"
                class="my-4 my-md-8"
            ></v-divider>

            <!-- Icons & Copyright -->
            <v-col
                cols="12"
                md="3"
                class="
                    mt-2
                    d-flex
                    flex-column
                    footer-dim-items
                    align-stretch
                    justify-stretch
                    justify-start
                    flex-grow-0
                    fit-width
                "
            >
                <div
                    class="
                        footer-icons-copyright
                        align-center
                        align-self-center
                        align-md-end
                        align-self-md-end
                        fit-width
                    "
                >
                    <SocialMediaLinks
                        :responsive-alignment="true"
                        v-once
                    ></SocialMediaLinks>
                    <div
                        class="text-center text-md-right"
                    >
                        <span>&copy; {{ new Date().getFullYear() }} {{ brandTitle }}</span>
                    </div>
                </div>

                <ContactMethodsList
                    v-once
                    class="
                        mt-8
                        d-flex
                        flex-column
                        align-center
                        align-self-center
                        align-md-end
                        align-self-md-end
                        fit-width
                    "
                ></ContactMethodsList>
            </v-col>
        </v-row>
        <v-row
            class="footer-dim-items"
            dense
        >
            <v-col
                class="
                    d-flex
                    flex-column
                    align-center
                    align-md-end
                "
            >
                <AppVersionInfo v-once></AppVersionInfo>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts" setup>
    import type { MenuItem } from '~/types';
    import SocialMediaLinks from '~/components/SocialMediaLinks/SocialMediaLinks.vue';
    import ContactMethodsList from '~/components/footer/ContactMethodsList/ContactMethodsList.vue';
    import AppVersionInfo from '~/components/footer/AppVersionInfo/AppVersionInfo.vue';
    import { useIsMobile } from '~/composables';
    import MainMenuAccordion from '~/components/menus/MainMenuAccordion/MainMenuAccordion.vue';

    const FooterItems = defineAsyncComponent(() => import('~/components/footer/FooterItems/FooterItems.vue'));

    withDefaults(
        defineProps<{
            navItems: Nilable<MenuItem[]>;
            navItemsAlt: Nilable<MenuItem[]>;
        }>(),
        {
            navItems: () => [],
            navItemsAlt: () => []
        }
    );

    const $config = useRuntimeConfig();
    const brandTitle = $config.public.brand.title as string;
    const { isMobile } = useIsMobile();
</script>

<style lang="scss">
.footer-main-container {
    .footer-dim-items {
        filter: contrast(0.4);
    }

    .footer-icons-copyright {
        filter: opacity(0.7);
    }
}
</style>
