<template>
    <div>
        <ul
            class="partners-group"
            :class="textAlignClass"
        >
            <li>
                <h6 class="text-h6 ma-0 pa-0 d-inline-block">Official Partner</h6>
                <img
                    class="nll-partner-badge d-inline-block"
                    :src="`${cdnBasePathMain}/static/common/images/svg/partners/NLL-Solid.svg`"
                    alt="NLL Official Partner"
                    aria-label="NLL Official Partner"
                    loading="lazy"
                />
            </li>
        </ul>
        <ul
            class="badges-group badges-cc"
            :class="textAlignClass"
        >
            <li>
                <img
                    :src="`${cdnBasePathMain}/static/common/images/svg/cc/cc-visa.svg`"
                    loading="lazy"
                    alt="Credit Card Visa"
                    aria-label="Credit Card Visa"
                />
            </li>
            <li>
                <img
                    :src="`${cdnBasePathMain}/static/common/images/svg/cc/cc-amex.svg`"
                    loading="lazy"
                    alt="Credit Card American Express"
                    aria-label="Credit Card American Express"
                />
            </li>
            <li>
                <img
                    :src="`${cdnBasePathMain}/static/common/images/svg/cc/cc-mastercard.svg`"
                    loading="lazy"
                    alt="Credit Card MasterCard"
                    aria-label="Credit Card MasterCard"
                />
            </li>
            <li>
                <img
                    :src="`${cdnBasePathMain}/static/common/images/svg/cc/cc-discover.svg`"
                    loading="lazy"
                    alt="Credit Card Discover"
                    aria-label="Credit Card Discover"
                />
            </li>
        </ul>
        <ul class="badges-group badges-social mt-6" v-if="socialConfig">
            <li v-for="item in socialConfig.links" :key="item.network">
                <a :href="item.link" target="_blank" :title="item.network">
                    <img
                        :src="getResourceCdnUrl(item.icons.url)"
                        loading="lazy"
                        :alt="`Social Media ${item.network}`"
                        :aria-label="`Social Media ${item.network}`"
                    />
                </a>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
    import { useCdnResource } from '~/composables';
    import type { SiteConfigurationObject } from '~/types';

    type SocialConfig = SiteConfigurationObject['social'];

    const props = defineProps<{
        responsiveAlignment?: Nilish<boolean>;
    }>();

    const $config = useRuntimeConfig();
    const { cdnBasePathMain, getResourceCdnUrl } = useCdnResource();
    const socialConfig = $config.public.social as SocialConfig;
    const textAlignClass = ref<string>(props.responsiveAlignment ? 'text-center text-md-end' : '');
</script>

<style lang="scss" scoped>
.nll-partner-badge {
    width: 42px;
    height: 42px;
    vertical-align: middle;
    filter: invert(0.5);
}

.partners-group {
    font-size: 84%;
    list-style: none;
    margin: 0 0 0.8rem 0;
    padding: 0;
    text-align: right;
}

.badges-group {
    list-style: none;
    margin: 0 0 0.8rem 0;
    padding: 0;
    text-align: right;
    white-space: nowrap;

    li {
        display: inline-block;
        margin: 0 0.6rem;
    }

    img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        filter: invert(0.5);
    }
}
</style>
