<template>
    <div class="text-size-fine opacity-20">
        {{ appName }} v{{ appVersion }} ({{ appCommitHash }})
    </div>
</template>

<script lang="ts" setup>
    const $config = useRuntimeConfig();
    const { appVersion, appName, appCommitHash } = $config.public;
</script>
